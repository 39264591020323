import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {HttpService} from "./services/http.service";
import {GoogleTagManagerService} from "angular-google-tag-manager";
import { MetaService } from './seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hub-contenidos-front';

  constructor(private router: Router, private gtmService: GoogleTagManagerService, private meta: MetaService) {
    this.gtmService.addGtmToDom();
    this.meta.updateTitle();
    this.meta.updateMetaInfo();
  }

  ngOnInit() {
    
    setTimeout(() => {
      window.location.reload();
    },3600000); // 1 horas

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
