import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../../services/http.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { EditPreferencesDialogComponent } from '../edit-preferences-dialog/edit-preferences-dialog.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private httpService: HttpService, private dialog: MatDialog, private router: Router) { }

    ngOnInit() {
    }

    
  openDialog() {
    const dialogRef = this.dialog.open(EditPreferencesDialogComponent, {
      width: '350px',
      data: {preferences: true},
      panelClass: 'custom-modalbox',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result.subscriber) {
        this.router.navigate(['/edit_confirmation']);
      }
    });
  }

  showPreferencesModal( values: any ): void {
    setTimeout(() => {
        const dialogRef = this.dialog.open(EditPreferencesDialogComponent, {
            width: '350px',
            data: {preferences: true},
            panelClass: 'custom-modalbox',
            autoFocus: false
          });
      
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result && result.subscriber) {
              this.router.navigate(['/edit_confirmation']);
            }
          });
    }, 200);
  }
}
