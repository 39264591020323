import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LayoutComponent } from './layout/layout.component';
import {ThankyouComponent} from './pages/public/thankyou/thankyou.component';
import {PreferencesComponent} from './pages/public/preferences/preferences.component';
import { ErrorpageComponent } from './pages/public/errorpage/errorpage.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'preferences',
    component: PreferencesComponent
  },
  {
    path: 'thankyou',
    component: ThankyouComponent
  },
  {
    path: 'about',
    component: LayoutComponent,
    loadChildren: './pages/public/about/about.module#AboutpageModule'
  },
  {
    path: 'members',
    component: LayoutComponent,
    loadChildren: './pages/public/members/members.module#MemberspageModule'
  },
  {
    path: 'error',
    component: LayoutComponent,
    loadChildren: './pages/public/errorpage/errorpage.module#ErrorpageModule'
  },
  {
    path: 'home',
    component: LayoutComponent,
    loadChildren: './pages/public/home/home.module#HomeModule',
    data: {
      title: 'Home',
      description:'Home'
    } 
  },
  {
    path: 'edit_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'edit_completed_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'sub2factor_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'subscribe_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'quincenal_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'unsubscribe_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'unsubscribe_completed_confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'terms',
    component: LayoutComponent,
    loadChildren: './pages/public/terms/terms.module#TermsModule'
  },
  {
    path: 'dinamic-reports/:id',
    component: LayoutComponent,
    loadChildren: './pages/public/report/report.module#ReportModule'
  },
  {
    path: 'search',
    component: LayoutComponent,
    loadChildren: './pages/public/search-results/search-results.module#SearchResultsModule'
  },
  {
    path: 'categories/:id',
    component: LayoutComponent,
    loadChildren: './pages/public/categories/categories.module#CategoriesModule'
  },
  {
    path: 'categories/:id/type/:typeid',
    component: LayoutComponent,
    loadChildren: './pages/public/categories/categories.module#CategoriesModule'
  },
  {
    path: 'book',
    component: LayoutComponent,
    loadChildren: './pages/public/book/book.module#BookModule'
  },
  {
    path: 'indicators',
    component: LayoutComponent,
    loadChildren: './pages/public/ifx/ifx.module#IfxModule'
  },
  {
    path: 'confirmation',
    component: LayoutComponent,
    loadChildren: './pages/public/confirmation/confirmation.module#ConfirmationModule'
  },
  {
    path: 'multimedia',
    component: LayoutComponent,
    loadChildren: './pages/public/multimedia/multimedia.module#MultimediaModule',
    data: {
      title: 'Multimedia',
      description:'Multimedia'
    } 
  },
  {
    path: 'multimedia/:id',
    component: LayoutComponent,
    loadChildren: './pages/public/multimedia-detail/multimedia-detail.module#MultimediaDetailModule'
  },
  {
    path: ':categoryurl',
    component: LayoutComponent,
    loadChildren: './pages/public/dynamic/dynamic.module#DynamicModule'
  },
  {
    path: ':categoryurl/:childcategoryurl',
    component: LayoutComponent,
    loadChildren: './pages/public/dynamic/dynamic.module#DynamicModule'
  },
  {
    path: ':categoryurl/:childcategoryurl/:subcategoryurl',
    component: LayoutComponent,
    loadChildren: './pages/public/dynamic/dynamic.module#DynamicModule'
  },
  {
    path: ':categoryurl/:childcategoryurl/:subcategoryurl/:reporturl',
    component: LayoutComponent,
    loadChildren: './pages/public/dynamic/dynamic.module#DynamicModule'
  },
  
  //{path: '**' , redirectTo: '/error'}
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
