import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { Request, Response } from "./http.service.model";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public authorization: string = null;
  private _URL_API: string = environment.URL_API;

  constructor(private http: HttpClient) {}

  public get(input: Request): Observable<HttpResponse<Response>> {
    let params = new URLSearchParams();
    for (let key in input.data) {
      if (input.data.hasOwnProperty(key)) {
        params.set(key, input.data[key]);
      }
    }
    let encodeData = input.data ? this.encodeURIObj(input.data) : "";
    let headers = this.headers();
    return this.http
      .get<Response>(
        `${this._URL_API}${input.path}${
          input.encode ? `?filter=${encodeData}` : encodeData
        }`,
        {
          observe: "response",
          headers: headers,
        }
      )
      .pipe(catchError(this.handleError));
  }

  public getHTML(input: Request): Observable<HttpResponse<Response>> {
    let params = new URLSearchParams();
    for (let key in input.data) {
      if (input.data.hasOwnProperty(key)) {
        params.set(key, input.data[key]);
      }
    }
    let encodeData = input.data ? this.encodeURIObj(input.data) : "";
    let headers = this.headers();
    const options = {
      observe: "response" as "body",
      headers: headers,
      responseType: "text" as "json",
    };
    return this.http
      .get<HttpResponse<Response>>(
        `${this._URL_API}${input.path}${
          input.encode ? `?filter=${encodeData}` : encodeData
        }`,
        {
          observe: "response" as "body",
          headers: headers,
          responseType: "text" as "json",
        }
      )
      .pipe(catchError(this.handleError));
  }

  public post(input: Request): Observable<HttpResponse<Response>> {
    let headers = this.headers();
    return this.http
      .post<Response>(this._URL_API + input.path, input.data, {
        observe: "response",
        headers: headers,
      })
      .pipe(catchError(this.handleError));
  }

  public put(input: Request): Observable<HttpResponse<Response>> {
    let headers = this.headers();
    return this.http.put<Response>(this._URL_API + input.path, input.data, {
      observe: 'response',
      headers: headers
    }).pipe(catchError(this.handleError));
  }

  public patch(input: Request): Observable<HttpResponse<Response>> {
    let headers = this.headers();
    return this.http.patch<Response>(this._URL_API + input.path, input.data, {
      observe: 'response',
      headers: headers
    }).pipe(catchError(this.handleError));
  }

  public delete(input: Request): Observable<HttpResponse<Response>> {
    let params = new URLSearchParams();
    for (let key in input.data) {
      if (input.data.hasOwnProperty(key)) {
        params.set(key, input.data[key]);
      }
    }
    let headers = this.headers();
    return this.http.delete<Response>(this._URL_API + input.path, {
      observe: 'response',
      headers: headers
    }).pipe(catchError(this.handleError));
  }

  public headers() {
    let headers: any = {};
    if(this.authorization) {
      headers.Authorization = this.authorization;
    }
    return headers;
  }

  public preload(flag: boolean) {
    console.log('preload', flag);
  }

  public encodeURIObj(input: object): string {
    return encodeURI(JSON.stringify(input));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    
    if( error.error.message ) {
      return throwError("Something bad happened; please try again later." + error.error.message);
    } else {
      return throwError("Something bad happened; please try again later.");
    }
  }
}
