import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';

import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../services/http.service';
import {DataService} from '../../../data.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-subscribe-dialog',
  templateUrl: './subscribe-dialog.component.html',
  styleUrls: ['./subscribe-dialog.component.scss']
})
export class SubscribeDialogComponent {
  public subscribeGroup: FormGroup;
  public subscriber: any = {};

  public title: string;
  public description: string;
  public source: string;

  public quincenal = false;
  public quincenalError = false;

  public id : any;
  public lastName : any;
  public name : any;
  public email : any;
  public subscriberId : any;
  public token: string;
  public selected: any;
  public isLoadLoader: boolean = false;

  public options: any = [{
    title: 'Bajo',
    description: 'Desea aprender los conceptos más básicos de economía y finanzas y después profundizar en ellos.',
    code: 'LOW'
  }, {
    title: 'Medio',
    description: 'Le son familiares conceptos básicos de economía y finanzas, pero quiere aprender temas más especializados.',
    code: 'MEDIUM'
  }, {
    title: 'Alto',
    description: 'Utiliza a diario conceptos especializados de economía y finanzas.',
    code: 'HIGH'
  }];

  public currentOption = '';

  constructor(
    private http: HttpService,
    private dataService: DataService,
    public dialogRef: MatDialogRef<SubscribeDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private gtmService: GoogleTagManagerService) {

    this.quincenal = data.quincenal;

    this.subscribeGroup = new FormGroup({
      subscriberId : new FormControl(''),
      name: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      knowledge: new FormControl('', Validators.required),
      accept: new FormControl(false, [Validators.required, Validators.requiredTrue]),
    });
  }

  public setCurrentOption(evt) {
    const option = this.options.find(e => e.code === evt.value);
    this.currentOption = option ? option.title : '';
  }

  public subscribeQuincenal() {
    
    this.isLoadLoader = true; // show loader
    this.dataService.subscriptionData = {
      subscriber: this.subscriber,
      subscriptions: []
    };
    this.http.post({
      path: 'public/subscribe',
      data: {
        subscriber: this.subscriber,
        subscriptions: [
          {
            type: 'QUINCENAL'
          }]
      }
    }).subscribe((res) => {
      this.tag('Suscripción boletin quincenal', 'Enviar','Formulario de suscripcion');
      this.dialogRef.close({subscriber: this.subscriber});
      
      
      // guardar token en localstorage
      if (res.body['token']) {
        localStorage.setItem('tk_', res.body['token'])  
      }
      
    },
    (error) => {
      this.quincenalError = true;
      const self = this;
      setTimeout(() =>{
        self.quincenalError = false;
      }, 6000);
    });
  }

  public subscribe() {
    if (this.quincenal) {
      return this.subscribeQuincenal();
    }
    this.dialogRef.close({subscriber: this.subscriber});
    this.dialogRef.afterClosed().subscribe((result:any) => {
      
      this.http.put({
        path: 'public/subscriptions?access_token=' + this.token,
        data : {
          subscriber: result.subscriber,
          subscriptions: this.selected.map(e => {
            return {
              type: 'REPORTTYPE',
              reportTypeId: e
            };
          })
        },
        encode: true
      }).subscribe((res) => {
        localStorage.setItem('from_report_suscriber', '1')
        this.router.navigate(['edit_completed_confirmation']);
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  tag(eventCategory, eventAction, eventLabel) {

    const gtmTag = {
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventLabel,
      event: 'eventclick'
    };
    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
    if (this.data) {
      this.getSubscriber();
    }
  }

  getSubscriber() {
    this.id = this.data.id;
    if(this.data.name == this.data.email){
      this.name = null;
    }else{
      this.name = this.data.name;
    }
    if(this.data.lastName == this.data.email){
      this.lastName = null;
    }else{
      this.lastName = this.data.lastName;
    }

    this.email = this.data.email;
    this.token = this.data.token;
    this.selected= this.data.selected;

    this.subscriber.id = this.id
    this.subscriber.name = this.name;
    this.subscriber.email = this.email;
    this.subscriber.lastName = this.lastName; 
  }
}
