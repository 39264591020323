import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../services/http.service';
import {environment} from '../../../../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-summary',
  templateUrl: './report-summary.component.html',
  styleUrls: ['./report-summary.component.scss']
})
export class ReportSummaryComponent implements OnInit {
  public tab = 'tab1';
  public reports: any;
  public divisasReports: any = [];
  public rentaReports: any = [];
  public headerReport: any;
  public area1Report: any;
  public area2Report: any;
  public area3Report: any;
  public area4Report: any;
  public assetBase: string = environment.URL_API;
  public companies: any;

  constructor(private http: HttpService, private gtmService: GoogleTagManagerService, public router: Router) { }

  ngOnInit() {
    this.loadOutstanding();
    this.loadReportTypes();
  }

  getCategory(reportType) {
    return reportType ? reportType.description : '';
    /* return reportType && reportType.mainCategory && reportType.mainCategory.length ?
      reportType.mainCategory[0].description : ''; */
  }

  private loadReportTypes() {
    const filter = {
      where: {
        code: {inq: ['RENTAFIJA', 'DIVISAS']}
      },
      include: ['childrenSubReportTypes'],
      order: 'updatedAt DESC',
      limit: 8
    };
    this.http.get({
      path: `public/categories/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      const data = res.body as any;
      const divisas = data.find(e => e.code === 'DIVISAS');
      const renta = data.find(e => e.code === 'RENTAFIJA');

      if (divisas && divisas.childrenSubReportTypes && divisas.childrenSubReportTypes.length) {
        const ids = divisas.childrenSubReportTypes.map(e => e.id);
        this.loadDivisasReports(ids);
      }

      if (renta && renta.childrenSubReportTypes && renta.childrenSubReportTypes.length) {
        const ids = renta.childrenSubReportTypes.map(e => e.id);
        this.loadRentaReports(ids);
      }
    });
  }

  openPdf(report) {
    const url = this.assetBase + `public/assets/reports-migrated/${report.pdfFolder}/${report.publishedYear}/${report.pdfFile}${!report.pdfFile.endsWith('.pdf') ? '.pdf' : ''}`;
    window.open(url);
  }

  private loadDivisasReports(reportTypeIds) {
    const filter = {
      where: {
        reportTypeId: {inq: reportTypeIds}
      },
      fields: ['id', 'name', 'strategyArea', 'sectionId', 'reportTypeId', 'publishedAt', 'smartContent', 'rTitle', 'url', 'companyId'],
      include: [{
        relation: 'reportType',
        scope: {
          include: ['mainCategory', 'subCategory']
        }
      }],
      order: 'updatedAt DESC',
      limit: 8
    };
    this.http.get({
      path: `public/reports-es/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      this.divisasReports = res.body;
    });
  }

  private loadRentaReports(reportTypeIds) {
    const filter = {
      where: {
        reportTypeId: {inq: reportTypeIds}
      },
      fields: ['id', 'name', 'strategyArea', 'sectionId', 'reportTypeId', 'publishedAt', 'smartContent', 'rTitle', 'url', 'companyId'],
      include: [{
        relation: 'reportType',
        scope: {
          include: ['mainCategory', 'subCategory']
        }
      }],
      order: 'updatedAt DESC',
      limit: 8
    };
    this.http.get({
      path: `public/reports-es/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      this.rentaReports = res.body;
    });
  }

  private loadOutstanding() {
    const filter = {
      where: {
        strategy: true
      },
      fields: ['id', 'name', 'strategyArea', 'sectionId', 'reportTypeId', 'publishedAt', 'smartContent', 'rTitle', 'url', 'companyId'],
      include: ['files', 'section', {
        relation: 'reportType',
        scope: {
          include: ['mainCategory', 'subCategory']
        }
      }],
      order: 'updatedAt DESC'
    };
    this.http.get({
      path: `public/reports-es/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      this.reports = res.body;

      this.reports = this.reports
          .map(e => {
            if (e.section && e.section.types && e.section.types.length) {
              e.type = e.section.types.find(k => k.key === e.sectionTypeKey);
            } else {
              e.type = null;
            }
            return e;
          });

      this.headerReport = this.reports.find(e => e.strategyArea === 'outstanding');
      this.area1Report = this.reports.find(e => e.strategyArea === 'report1');
      this.area2Report = this.reports.find(e => e.strategyArea === 'report2');
      this.area3Report = this.reports.find(e => e.strategyArea === 'report3');
      this.area4Report = this.reports.find(e => e.strategyArea === 'report4');
      this.http.get({
        path: `public/companies/`,
        data: {
          order: 'name ASC'
        },
        encode: true,
      }).subscribe((res) => {
        this.companies = res.body;
        const getName = this.companies.find(e => e.id === this.headerReport.companyId);
        const getName1 = this.companies.find(e => e.id === this.area1Report.companyId);
        const getName2 = this.companies.find(e => e.id === this.area2Report.companyId);
        const getName3 = this.companies.find(e => e.id === this.area3Report.companyId);
        const getName4 = this.companies.find(e => e.id === this.area4Report.companyId);
        this.headerReport.companyName = getName ? getName.name : this.headerReport.reportType.description;
        this.area1Report.companyName = getName1 ? getName1.name : this.area1Report.reportType.description;
        this.area2Report.companyName = getName2 ? getName2.name : this.area2Report.reportType.description;
        this.area3Report.companyName = getName3 ? getName3.name : this.area3Report.reportType.description;
        this.area4Report.companyName = getName4 ? getName4.name : this.area4Report.reportType.description;
      })
    });
  }

  tag(eventCategory, eventAction, eventLabel, getUrl=null, detail=null) {
    (getUrl) ? (detail) ? eventLabel = 'Detalles del informe - ' + window.location.origin + eventLabel : eventLabel = window.location.origin + eventLabel : '';
    const gtmTag = {
      EventoCategoria: eventCategory,
      EventoTipo: eventAction,
      EventoLanding: 'Vision Davivienda',
      EventoEtiqueta: eventLabel,
      event: 'eventclick'
    };
    this.gtmService.pushTag(gtmTag);
  }

  getReportLink(reportId){

    this.http.get({
      path: `public/reports/`,
      data: { where: {id: reportId}},
      encode: true
    }).subscribe((res) => {
      if (res.body && (res.body as any).length) {
        let report =  res.body[0];

        this.http.get({
          path: `public/urls/`,
          data: {where: { reportId: ""+report.id} },
          encode: true
        }).subscribe(
        (response) => {
          if (response) {
            
            let last = "";
            if(Object.keys(response.body).length){
              last = response.body[0].url+"/";
            }else{
              last = report.id+"/";
            }

            let subcat;
            if(report.companyId != "null"){
              subcat = report.companyId;
            }else{
              subcat = report.reportTypeId;
            }
            
            this.http.get({
              path: `public/urls/`,
              data: {where: { subcategoryId: ""+ subcat} },
              encode: true
            }).subscribe(
            (response2) => {
              if (response2) {
                if(Object.keys(response2.body).length){
                  let middle;
                  if(response2.body[0].fatherUrlId){
                    // 4 Params
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { id: ""+ response2.body[0].fatherUrlId} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          middle =  response3.body[0].url+"/"+response2.body[0].url+"/";

                          let beg;
                          this.http.get({
                            path: `public/urls/`,
                            data: {where: { categoryId: ""+ response3.body[0].categoryId, component: "categories"} },
                            encode: true
                          }).subscribe(
                          (response4) => {
                            if (response4) {
                              if(Object.keys(response4.body).length){
                                beg = response4.body[0].url+"/";
                                this.router.navigate([beg+middle+last]);
                              }
                            }
                          });
                        }
                      }
                    });

                  }else{
                    // 3 Params
                    middle = response2.body[0].url+"/";

                    let beg;
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { categoryId: ""+ response2.body[0].categoryId, component: "categories"} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          beg = response3.body[0].url+"/";
                          this.router.navigate([beg+middle+last]);
                        }
                      }
                    });
                    
                  }
                }
              }
            });
          }
        });
      }
    });
  }
}
