import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../services/http.service';
import { combineLatest } from "rxjs";
import { Alert } from 'selenium-webdriver';
import { param } from 'jquery';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() pages: Array<any>;
  @Output() back = new EventEmitter<boolean>();

  constructor( private location: Location, private router: Router, private activatedRoute: ActivatedRoute,private http: HttpService) {
  }

  ngOnInit() {

  }

  goBack() {
    this.back.emit(true);
    const oparams = this.activatedRoute.params;
      const oqueryParams = this.activatedRoute.queryParams;
    
      combineLatest(oparams, oqueryParams, (iparams) => ({
        iparams
      })).subscribe((response) => {
        const params = response.iparams;
        if(Object.keys(response.iparams).length){
          if(this.router.url.includes("/multimedia/") && params.id){
            this.router.navigate(["/multimedia"]);
          }else if(params.reporturl){
            this.router.navigate(["/"+params.categoryurl+"/"+params.childcategoryurl+"/"+params.subcategoryurl]);
          }else{
            if(params.subcategoryurl){
              this.router.navigate(["/"+params.categoryurl+"/"+params.childcategoryurl]);
            }else{
              if(params.childcategoryurl){
                this.router.navigate(["/"+params.categoryurl]);
              }else{
                if(params.categoryurl){
                  this.router.navigate(["/home"]);
                }
              }
            }
          }
        }else{
          this.router.navigate(["/home"]);
        }
      });
    
    //this.location.back();this.location.back();
  }

  goLink(page: any, i){
    
    if(page.label == 'Multimedia'){
      this.router.navigate(["/multimedia"]);
    }else{
      if(page.link.length == 2){
        this.http.get({
          path: `public/urls/`,
          data: {where: { categoryId: page.link[1], component: 'categories'} },
          encode: true
        }).subscribe(
        (response) => {
          if(response){
            if(Object.keys(response.body).length){
              this.router.navigate(["/"+response.body[0].url]);
            }
          }
        });
      }else if(page.link.length == 4){
        this.http.get({
          path: `public/urls/`,
          data: {where: { categoryId: page.link[1], component: 'categories'} },
          encode: true
        }).subscribe(
        (response) => {
          if(response){
            if(Object.keys(response.body).length){
              this.http.get({
                path: `public/urls/`,
                data: {where: { subcategoryId: page.link[3], component: "subcategories" } },
                encode: true
              }).subscribe(
              (response2) => {
                if(response2){
                  if(Object.keys(response2.body).length){
                    if(!response2.body[0].fatherUrlId){
                      this.router.navigate(["/" + response.body[0].url + "/" + response2.body[0].url]);
                    }else{
                      this.http.get({
                        path: `public/urls/`,
                        data: {where: { id: response2.body[0].fatherUrlId } },
                        encode: true
                      }).subscribe(
                      (response3) => {
                        if(response3){
                          if(Object.keys(response3.body).length){
                            this.router.navigate(["/" + response.body[0].url + "/" + response3.body[0].url + "/" +response2.body[0].url]);
                          }
                        }
                      });
                    }
                    
                  }
                }
              });
            }
          }
        });
      }
      if(page.link[0] == '/categories'){
        this.http.get({
          path: `public/urls/`,
          data: {where: { url: page.link[1], component: 'categories'} },
          encode: true
        }).subscribe(
        (response) => {
          if(response){
            if(Object.keys(response.body).length){
              this.router.navigate(["/"+response.body[0].url]);
            }
          }
        });
      }

    }
    
  }

}
