import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { Router } from '@angular/router';

@Component({
  selector: 'app-remarkable-area',
  templateUrl: './remarkable-area.component.html',
  styleUrls: ['./remarkable-area.component.scss']
})
export class RemarkableAreaComponent implements OnInit {
  public reports: any;
  public headerReport: any;
  public area1Report: any;
  public area2Report: any;
  public area3Report: any;
  public area4Report: any;

  public assetBase: string = environment.URL_API;
  constructor(private http: HttpService, private gtmService: GoogleTagManagerService,
    public router: Router) { }

  ngOnInit() {
    this.loadOutstanding();
  }

  public getType(item: any) {
    return item && item.multimediaType ? item.multimediaType.name.toUpperCase() : 'none';
  }

  getCategory(report) {
    if (!report) return '';
    let category = report.reportType.description || '';
    if (report.reportType && report.reportType.mainCategory && report.reportType.mainCategory.length &&
        report.reportType.mainCategory[0].code && report.reportType.mainCategory[0].code === 'ANLISISDECOMPAAS' &&
        report.company && report.company.name) {
          category = report.company.name;
        }
    return category;
  }

  private loadOutstandingMultimedia() {
    const filter = {
      where: {
        outstandingMainHome: true
      },
      include: ['files']
    };
    this.http.get({
      path: `public/contents/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      const multimedia: any = (res.body as any).map(e => {
        e.files = e.files.filter(j => (e.outstandingMainHomeArea === 'header' &&
          j.key.toLowerCase() === 'outstandingimage') ||
          (e.outstandingMainHomeArea !== 'header' &&
            j.key.toLowerCase() === 'thumbnail'));
        e.image = e.files && e.files.length ? e.files[0] : null;
        return {
          id: e.id,
          rTitle: e.title,
          smartContent: e.description,
          outstandingMainHomeArea: e.outstandingMainHomeArea,
          image: e.image,
          publishedAt: e.createdAt,
          multimedia: true,
          multimediaType: e.multimediaType,
          reportType: {
            description: e && e.params && e.params.categoryName ? e.params.categoryName : 'CORREDORES DAVIVIENDA',
          },
        };
      });

      const headerReport = multimedia.find(e => e.outstandingMainHomeArea === 'header');
      const area1Report = multimedia.find(e => e.outstandingMainHomeArea === 'area1');
      const area2Report = multimedia.find(e => e.outstandingMainHomeArea === 'area2');
      const area3Report = multimedia.find(e => e.outstandingMainHomeArea === 'area3');
      const area4Report = multimedia.find(e => e.outstandingMainHomeArea === 'area4');

      this.headerReport = headerReport ? headerReport : this.headerReport;
      this.area1Report = area1Report ? area1Report : this.area1Report;
      this.area2Report = area2Report ? area2Report : this.area2Report;
      this.area3Report = area3Report ? area3Report : this.area3Report;
      this.area4Report = area4Report ? area4Report : this.area4Report;
    });
  }

  public getLink(entry: any) {
    if (entry.multimedia) {
      if(entry.url){
        return ['/multimedia', entry.url];
      }else{
        return ['/multimedia', entry.id];
      }
    }
    
    return ['/reports', entry.id];
  }

  public getFriendlyUrl(entry: any) {
    if(entry.multimedia) {
      this.go(entry);
    }

    this.getReportLink(entry.id);
    
  }

  private loadOutstanding() {
    const filter = {
      where: {
        outstanding: true
      },
      fields: ['id', 'name', 'outstandingArea', 'sectionId', 'reportTypeId', 'publishedAt', 'smartContent', 'rTitle', 'companyId', 'languageId', 'url'],
      include: ['files', 'section', 'company', {
        relation: 'reportType',
        scope: {
          include: ['mainCategory', 'subCategory']
        }
      }]
    };
    this.http.get({
      path: `public/reports-es/`,
      data: filter,
      encode: true
    }).subscribe((res) => {
      this.reports = res.body;
      this.reports = this.reports
          .filter(e => e.files && e.files.length && e.files.find(k => k.key.toLowerCase() === 'outstandingimage'))
          .map(e => {
            e.files = e.files.filter(j => j.key.toLowerCase() === 'outstandingimage');
            e.image = e.files && e.files.length ? e.files[0] : null;

            if (e.section && e.section.types && e.section.types.length) {
              e.type = e.section.types.find(k => k.key === e.sectionTypeKey);
            } else {
              e.type = null;
            }
            return e;
          });

      this.headerReport = this.reports.find(e => e.outstandingArea === 'header');
      this.area1Report = this.reports.find(e => e.outstandingArea === 'area1');
      this.area2Report = this.reports.find(e => e.outstandingArea === 'area2');
      this.area3Report = this.reports.find(e => e.outstandingArea === 'area3');
      this.area4Report = this.reports.find(e => e.outstandingArea === 'area4');
      this.loadOutstandingMultimedia();
    });
  }

  tag(eventCategory: any, eventAction: any, eventLabel: any) {

    const gtmTag = {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: window.location.origin + eventLabel,
      eventvalue: '',
      event: 'eventClick'
    };
    this.gtmService.pushTag(gtmTag);
  }

  getReportLink(reportId: any){
    
    this.http.get({
      path: `public/reports/`,
      data: { where: {id: reportId}},
      encode: true
    }).subscribe((res) => {
      if (res.body && (res.body as any).length) {
        let report =  res.body[0];

        this.http.get({
          path: `public/urls/`,
          data: {where: { reportId: ""+report.id} },
          encode: true
        }).subscribe(
        (response) => {
          if (response) {
            
            let last = "";
            if(Object.keys(response.body).length){
              last = response.body[0].url+"/";
            }else{
              last = report.id+"/";
            }

            let subcat;
            if(report.companyId != "null"){
              subcat = report.companyId;
            }else{
              subcat = report.reportTypeId;
            }
            
            this.http.get({
              path: `public/urls/`,
              data: {where: { subcategoryId: ""+ subcat} },
              encode: true
            }).subscribe(
            (response2) => {
              if (response2) {
                if(Object.keys(response2.body).length){
                  let middle;
                  if(response2.body[0].fatherUrlId){
                    // 4 Params
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { id: ""+ response2.body[0].fatherUrlId} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          middle =  response3.body[0].url+"/"+response2.body[0].url+"/";

                          let beg;
                          this.http.get({
                            path: `public/urls/`,
                            data: {where: { categoryId: ""+ response3.body[0].categoryId, component: "categories"} },
                            encode: true
                          }).subscribe(
                          (response4) => {
                            if (response4) {
                              if(Object.keys(response4.body).length){
                                beg = response4.body[0].url+"/";
                                this.router.navigate([beg+middle+last]);
                              }
                            }
                          });
                        }
                      }
                    });

                  }else{
                    // 3 Params
                    middle = response2.body[0].url+"/";

                    let beg;
                    this.http.get({
                      path: `public/urls/`,
                      data: {where: { categoryId: ""+ response2.body[0].categoryId, component: "categories"} },
                      encode: true
                    }).subscribe(
                    (response3) => {
                      if (response3) {
                        if(Object.keys(response3.body).length){
                          beg = response3.body[0].url+"/";
                          this.router.navigate([beg+middle+last]);
                        }
                      }
                    });
                    
                  }
                }
              }
            });
          }
        });
      }
    });
  }

  go(item: any) {
    this.http.get({
      path: `public/urls/`,
      data: {where: { contentId: ""+item.id, component: "multimedia"} },
      encode: true
    }).subscribe(
    (response) => {
      if (response) {
        if(Object.keys(response.body).length){
          this.router.navigate(['/multimedia', response.body[0].url]);
        }else{
          this.router.navigate(['/multimedia', item.id]);
        }
      }
    });
  }
}
